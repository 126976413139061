import React from 'react';
import Layout from '../components/layout';
import {navigate} from 'gatsby';
import Button from '../components/ui/button/button';

export default () => {
	const goBackHandler = () => {
		navigate('/');
	};

	return (
		<Layout noHeaderImage={true}>
			<h1>Tak for din henvendelse</h1>
			<p>Jeg venter tilbage med svar, så hurtigt som muligt.</p>
			<Button clicked={goBackHandler}>Go back</Button>
		</Layout>
	);
};
